import {
    array,
    boolean,
    nullable,
    number,
    object,
    optional,
    record,
    string,
} from "superstruct";

import DiscountSchema from "./DiscountSchema";

const CartItemSchema = object({
    id: number(),
    properties: nullable(record(string(), string())),
    quantity: number(),
    quantity_rule: optional(record(string(), nullable(number()))),
    variant_id: number(),
    key: string(),
    title: string(),
    price: number(),
    original_price: number(),
    discounted_price: number(),
    line_price: number(),
    original_line_price: number(),
    total_discount: number(),
    discounts: array(object({ amount: number(), title: string() })),
    sku: nullable(string()),
    grams: number(),
    vendor: string(),
    taxable: boolean(),
    product_id: number(),
    product_has_only_default_variant: boolean(),
    gift_card: boolean(),
    final_price: number(),
    final_line_price: number(),
    url: string(),
    featured_image: object({
        aspect_ratio: number(),
        alt: string(),
        height: number(),
        url: string(),
        width: number(),
    }),
    image: string(),
    handle: string(),
    requires_shipping: boolean(),
    product_type: string(),
    product_title: string(),
    product_description: nullable(string()),
    variant_title: nullable(string()),
    variant_options: array(string()),
    options_with_values: array(record(string(), string())),
    line_level_discount_allocations: optional(
        array(
            object({ amount: number(), discount_application: DiscountSchema })
        )
    ),
    line_level_total_discount: number(),
    has_components: boolean(),
});

export default CartItemSchema;
