import { Infer, object, string } from "superstruct";
import { InjectionToken } from "tsyringe";

export const MediaManagerApplicationConfigSchema = object({
    designFeedPageWidgetPlaceholderSelector: string(),
    designFeedProductWidgetPlaceholderSelector: string(),
});

export type MediaManagerApplicationConfigInterface = Infer<
    typeof MediaManagerApplicationConfigSchema
>;

export const MediaManagerApplicationConfigToken = Symbol(
    "MediaManagerApplicationConfigToken"
) as InjectionToken<MediaManagerApplicationConfigInterface>;
